<script>
// import vue2Dropzone from "vue2-dropzone";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Add Product",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // vueDropzone: vue2Dropzone,
    Multiselect,
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      items: [
        {
          text: "Home",
        },
        {
          text: "ประเมินราคาก่อนซ่อม",
          active: true,
        },
      ],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [5, 25, 50, 100],
      filter: null,
      filterOn: [],
      filter2: null,
      filterOn2: [],
      rowsTest: [],
      sortBy: "age",
      sortDesc: false,
      sortBy2: "age",
      sortDesc2: false,
      fields: [
        {
          key: "userId",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "familyName",
          sortable: true,
        },
        "action",
      ],
      fields2: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "first_name",
          sortable: true,
        },
        "action",
      ],
      autoClose: "",
      autoClose2: "",
      autoClose3: "",
      autoClose4: "",
      title: "ประเมินราคาก่อนซ่อม",
      form: {
        fname: "",
        lname: "",
        city: "",
        state: "",
        zipcode: "",
      },
      tooltipform: {
        name: "",
        job: "",
        fname: "",
        lname: "",
        username: "",
        city: "",
        state: "",
        test1: "",
        test2: "",
        test3: "",
        test4: "",
        test5: "",
        test6: "",
        test7: "",
        test8: "",
        test9: "",
        test10: "",
        test11: "",
        test12: "",
        test13: "",
        test14: "",
        test15: "",
        test16: "",
        test17: "",
        test18: "",
        test19: "",
        test20: "",
        test21: "",
        test22: "",
        test23: "",
        test24: "",
        test25: "",
      },
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: "",
      },
      options6: ["เขียว", "ขาว", "ดำ", "ม่วง", "ชมพู", "เทา"],
      options7: [
        {
          id: 1,
          name: "ศูนย์บริการ",
        },
        {
          id: 2,
          name: "อู่",
        },
        {
          id: 3,
          name: "ห้่าง",
        },
      ],
      options3: ["เขียว", "ขาว", "ดำ", "ม่วง", "ชมพู", "เทา"],
      options2: [
        {
          id: 1,
          name: "Suzuki",
        },
        {
          id: 2,
          name: "City",
        },
        {
          id: 3,
          name: "Civic",
        },
      ],
      options4: ["1500", "1800", "2000", "2200", "2400", "2600"],
      options5: [
        {
          id: 1,
          name: "อ่อนนุช",
        },
        {
          id: 2,
          name: "เลย",
        },
        {
          id: 3,
          name: "เอกสหะกรุ๊ป",
        },
      ],
      options8: [
        {
          id: 1,
          name: "ค่าแรง",
        },
        {
          id: 2,
          name: "เลย",
        },
        {
          id: 3,
          name: "เอกสหะกรุ๊ป",
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      overlayFlag: false,
      value: null,
      value2: "",
      value3: null,
      value4: null,
      value5: null,
      rows: [],
      dataTest: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      value1: null,
      options: [
        "High Quality",
        "Leather",
        "Notifications",
        "Sizes",
        "Different Color",
      ],
    };
  },
  validations: {
    form: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zipcode: {
        required,
      },
    },
    tooltipform: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      username: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
    },
    range: {
      minlen: {
        required,
        minLength: minLength(6),
      },
      maxlength: {
        required,
        maxLength: maxLength(6),
      },
      between: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(10),
      },
      minval: {
        required,
        minValue: minValue(6),
      },
      maxval: {
        required,
        maxValue: maxValue(6),
      },
      rangeval: {
        required,
        minValue: minValue(6),
        maxValue: maxValue(100),
      },
      expr: {
        required,
      },
    },
    typeform: {
      name: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      email: {
        required,
        email,
      },
      url: {
        required,
        url,
      },
      digit: {
        required,
        numeric,
      },
      number: {
        required,
        numeric,
      },
      alphanum: {
        required,
        alphaNum,
      },
      textarea: {
        required,
      },
    },
  },
  created() {
    this.getDatatable();
    this.selected = JSON.parse(localStorage.getItem("selected"));
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      // const bodyFormData = new FormData()
      // bodyFormData.append('name', this.tooltipform.name)
      // bodyFormData.append('job', this.tooltipform.job)
      useNetw
        .get("api/users", {
          params: {
            page: 1,
            perPage: 10,
            branchId: [1],
          }
        })
        .then((response) => {
          this.rows = response.data.data;
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getSelect: function() {
      useNetw
        .get(`${appConfig.api.baseUrlTest}api/users`)
        .then((response) => {
          this.dataTest = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    getDatatable: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/users", {
          params: {
            page: 1,
            perPage: 10,
            branchId: [1],
          }
        })
        .then((response) => {
          this.rowsTest = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getDatatable();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getDatatable();
    },
    onRowSelected(rowsTest) {
      this.selected = rowsTest;
      localStorage.setItem("selected", JSON.stringify(this.selected));
      // this.rowsSelect.push(this.selected)
      // this.userData = JSON.parse(localStorage.getItem('selected'))
    },
    onRowRemove(rowsTest) {
      // this.selected = rowsTest
      // alert(JSON.stringify(rowsTest))
      this.selected.splice(rowsTest.index, 1);
      localStorage.setItem("selected", JSON.stringify(this.selected));
    },
    selectAllRows() {
      this.$refs.selectableTable2.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable2.clearSelected();
    },
    // selectThirdRow() {
    //   // Rows are indexed from 0, so the third row is index 2
    //   this.$refs.selectableTable.selectRow(2)
    // },
    // unselectThirdRow() {
    //   // Rows are indexed from 0, so the third row is index 2
    //   this.$refs.selectableTable.unselectRow(2)
    // }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls="addproduct-billinginfo-collapse"
              v-b-toggle.accordion-1
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <!-- <h5 class="font-size-16 mb-1">ค่าอะไหล่</h5> -->
                    <!-- <p class="text-muted text-truncate mb-0">
                      Fill all information below
                    </p> -->
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <form @submit.prevent="tooltipForm" autocomplete="off">
                  <div class="row align-items-end">
                    <div class="col-6 col-sm-4 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip03">สาขา</label>
                        <multiselect
                          v-model="value2"
                          label="name"
                          :options="options5"
                          class="helo"
                          :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.lname.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.tooltipform.city.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.city.required"
                            >Please provide a valid city.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip03">เลขที่ใบประเมิน</label>
                        <input
                          id="validationTooltip06"
                          v-model="tooltipform.name"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.city.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.tooltipform.city.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.city.required"
                            >Please provide a valid city.</span
                          >
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-auto col-sm-12 col-md-6">
                  <div class="mb-3 position-relative">
                    <button class="btn btn-primary m-1" type="submit">
                      Search From CA</button
                    >
                    <button class="btn btn-primary m-1" type="submit">
                      Add From Stock
                    </button>
                  </div>
                </div> -->
                    <div class="col-6 col-sm-3 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip03">เลขตัวถังรถ</label>
                        <input
                          id="validationTooltip05"
                          v-model="tooltipform.name"
                          type="text"
                          class="form-control"
                          autocomplete="off"
                          :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.city.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.tooltipform.city.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.city.required"
                            >Please provide a valid city.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-2 col-md-2">
                      <div class="mb-3 position-relative">
                        <button class="btn btn-primary" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip04">วันที่ใบประเมิน{{autoClose}}</label>
                        <date-picker
                          v-model="autoClose"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.tooltipform.state.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.state.required"
                            >Please provide a valid state.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01">รหัสลูกค้า</label>
                        <input
                          id="validationTooltip15"
                          v-model="tooltipform.name"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.city.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.tooltipform.lname.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.lname.required"
                            >Please provide valid Model.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip02">เลขทะเบียน</label>
                        <input
                          id="validationTooltip02"
                          v-model="tooltipform.name"
                          type="text"
                          class="form-control"
                          value="Otto"
                          :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.lname.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.tooltipform.lname.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.lname.required"
                            >Please provide valid Car type.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipUsername1"
                          >ประเภท Shop</label
                        >
                        <div class="input-group">
                          <multiselect
                            v-model="value"
                            label="name"
                            :options="options7"
                            class="helo"
                            :class="{
                              'is-invalid':
                                submitform && $v.tooltipform.city.$error,
                            }"
                          ></multiselect>
                          <div
                            v-if="submitform && $v.tooltipform.username.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.tooltipform.username.required"
                              >Please choose a unique and valid username.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01"
                          >ภาษีมูลค่าเพิ่ม (%)</label
                        >
                        <input
                          id="validationTooltipUsername2"
                          v-model="tooltipform.test1"
                          type="text"
                          class="form-control"
                          aria-describedby="validationTooltipUsernamePrepend2"
                          :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.username.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.tooltipform.fname.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.fname.required"
                            >Please provide valid Color.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip02"
                          >ส่วนลดอะไหล่ (%)</label
                        >
                        <input
                          id="validationTooltipUsername3"
                          v-model="tooltipform.test1"
                          type="text"
                          class="form-control"
                          aria-describedby="validationTooltipUsernamePrepend2"
                          :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.username.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.tooltipform.lname.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.lname.required"
                            >Please provide valid Last name.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipUsernamePrepend2"
                          >ส่วนลดค่าแรง (%)</label
                        >
                        <div class="input-group">
                          <input
                            id="validationTooltipUsername4"
                            v-model="tooltipform.test1"
                            type="text"
                            class="form-control"
                            aria-describedby="validationTooltipUsernamePrepend2"
                            :class="{
                              'is-invalid':
                                submitform && $v.tooltipform.username.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.tooltipform.username.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.tooltipform.username.required"
                              >Please choose a unique and valid username.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip02">
                          อ้างอิงใบแจ้งซ่อม</label
                        >
                        <input
                          id="validationTooltip08"
                          v-model="tooltipform.test1"
                          type="text"
                          class="form-control"
                          value="Otto"
                          :class="{
                            'is-invalid':
                              submitform && $v.tooltipform.lname.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.tooltipform.lname.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.tooltipform.lname.required"
                            >Please provide valid Last name.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="float-end m-2 pb-2">
                    <button class="btn btn-primary">Save</button>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>

          <div class="row">
            <div class="col-md-7">
              <div class="card">
                <a
                  href="javascript: void(0);"
                  class="text-dark collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="addproduct-img-collapse"
                  v-b-toggle.accordion-2
                >
                  <div class="p-4">
                    <div class="media align-items-center">
                      <div class="me-3">
                        <div class="avatar-xs">
                          <div
                            class="avatar-title rounded-circle bg-soft-primary text-primary"
                          >
                            02
                          </div>
                        </div>
                      </div>
                      <div class="media-body overflow-hidden">
                        <h5 class="font-size-16 mb-1">ค่าแรง</h5>
                        <!-- <p class="text-muted text-truncate mb-0">
                      Fill all information below
                    </p> -->
                      </div>
                      <i
                        class="mdi mdi-chevron-up accor-down-icon font-size-24"
                      ></i>
                    </div>
                  </div>
                </a>

                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  data-parent="#addproduct-accordion"
                >
                  <div class="p-4 border-top">
                    <div class="row align-items-end">
                      <div class="col-6 col-sm-6 col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltip01">ประเภทรายการ</label>
                          <multiselect
                            v-model="value2"
                            label="name"
                            :options="options8"
                            class="helo"
                            :class="{
                              'is-invalid':
                                submitform && $v.tooltipform.lname.$error,
                            }"
                          >
                          </multiselect>
                          <div
                            v-if="submitform && $v.tooltipform.fname.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.tooltipform.fname.required"
                              >Please provide valid Model.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-4 col-sm-4 col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltip01">รหัสรายการ</label>
                          <input
                            id="validationTooltip07"
                            v-model="tooltipform.test8"
                            type="text"
                            class="form-control"
                            value="Otto"
                            :class="{
                              'is-invalid':
                                submitform && $v.tooltipform.lname.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.tooltipform.fname.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.tooltipform.fname.required"
                              >Please provide valid Color.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-4 col-sm-4 col-md-4">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltip01">รหัสหน่วยนับ</label>
                          <input
                            id="validationTooltip09"
                            v-model="tooltipform.test10"
                            type="text"
                            class="form-control"
                            value="Otto"
                            :class="{
                              'is-invalid':
                                submitform && $v.tooltipform.lname.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.tooltipform.fname.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.tooltipform.fname.required"
                              >Please provide valid Color.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-2 col-sm-3 col-md-2">
                        <div class="mb-3 position-relative">
                          <button class="btn btn-primary">Search</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <b-overlay
                        :show="overlayFlag"
                        :spinner-variant="this.$overlayVariant"
                        :spinner-type="this.$overlayType"
                        :rounded="this.$overlayRounded"
                      >
                        <div class="card">
                          <div class="card-body shadow">
                            <h4 class="card-title">รายการอะไหล่</h4>
                            <div class="row mt-4">
                              <div class="col-sm-12 col-md-6">
                                <div
                                  id="tickets-table_length"
                                  class="dataTables_length"
                                >
                                  <label
                                    class="d-inline-flex align-items-center"
                                  >
                                    แสดงผล&nbsp;
                                    <b-form-select
                                      v-model="perPage"
                                      size="sm"
                                      :options="pageOptions"
                                      @input="handlePageChange"
                                    ></b-form-select
                                    >&nbsp;รายการ
                                  </label>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-6">
                                <div
                                  id="tickets-table_filter"
                                  class="dataTables_filter text-md-end"
                                >
                                  <label
                                    class="d-inline-flex align-items-center"
                                  >
                                    Search:
                                    <b-form-input
                                      v-model="filter"
                                      type="search"
                                      placeholder="Search..."
                                      class="form-control form-control-sm ms-2"
                                    ></b-form-input>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="table-responsive mb-0">
                              <b-table
                                :items="rowsTest"
                                :fields="fields"
                                :current-page="1"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                responsive="sm"
                                ref="selectableTable2"
                                selectable
                                @row-selected="onRowSelected"
                              >
                                <template #cell(selected)="{ rowSelected }">
                                  <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                    <span class="sr-only">Selected</span>
                                  </template>
                                  <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                    <span class="sr-only">Not selected</span>
                                  </template>
                                </template>
                                <template v-slot:cell(action)>
                                  <ul class="list-inline mb-0">
                                    <li class="list-inline-item">
                                      <a
                                        href="javascript:void(0);"
                                        class="px-2 text-primary"
                                        v-b-tooltip.hover
                                        title="Edit"
                                      >
                                        <i class="uil uil-pen font-size-18"></i>
                                      </a>
                                    </li>
                                    <li class="list-inline-item">
                                      <a
                                        href="javascript:void(0);"
                                        class="px-2 text-danger"
                                        v-b-tooltip.hover
                                        title="Delete"
                                      >
                                        <i
                                          class="uil uil-trash-alt font-size-18"
                                        ></i>
                                      </a>
                                    </li>
                                  </ul>
                                </template>
                              </b-table>
                              <p class="float-end">
                                <b-button
                                  class="m-2"
                                  size="sm"
                                  @click="selectAllRows"
                                  >Select all</b-button
                                >
                                <b-button
                                  class="m-2"
                                  size="sm"
                                  @click="clearSelected"
                                  >Clear selected</b-button
                                >
                              </p>
                            </div>
                            <div class="row">
                              <div class="col">
                                <div class="align-items-center">
                                  แสดงผล {{ rowsperPage }} หน้า
                                  {{ rowsPage }} of {{ rowsTotalpages }} total
                                  {{ totalRecords }}
                                </div>
                                <div
                                  class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                  <ul
                                    class="pagination pagination-rounded mb-0"
                                  >
                                    <b-pagination
                                      v-model="currentPage"
                                      :total-rows="totalRecords"
                                      :per-page="perPage"
                                      @change="handleChangePage"
                                    ></b-pagination>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-overlay>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class="col-md-5">
              <b-overlay
                :show="overlayFlag"
                :spinner-variant="this.$overlayVariant"
                :spinner-type="this.$overlayType"
                :rounded="this.$overlayRounded"
              >
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">รายการที่เลือก</h4>
                    <div class="row mt-4"></div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        :items="selected"
                        :fields="fields"
                        :current-page="1"
                        :sort-by.sync="sortBy2"
                        :sort-desc.sync="sortDesc2"
                        :filter="filter2"
                        :filter-included-fields="filterOn2"
                        @filtered="onFiltered"
                        responsive="sm"
                      >
                        <template v-slot:cell(action)="rowsTest">
                          <ul class="list-inline mb-0">
                            <!-- <li class="list-inline-item">
                        <a
                          href="javascript:void(0);"
                          class="px-2 text-primary"
                          v-b-tooltip.hover
                          title="Edit"
                        >
                          <i class="uil uil-pen font-size-18"></i>
                        </a>
                      </li> -->
                            <li class="list-inline-item">
                              <a
                                href="javascript:void(0);"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="onRowRemove(rowsTest)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </template>
                        <template v-slot:cell(num)>
                          <input
                            id="validationTooltipa2"
                            v-model="tooltipform.test13"
                            type="text"
                            class="form-control"
                            value="Mark"
                            :class="{
                              'is-invalid':
                                submitform && $v.tooltipform.fname.$error,
                            }"
                          />
                        </template>
                      </b-table>
                      <div class="row">
                        <div class="col-4 font-size-14">
                          มูลค่าสินค้า: 4000
                        </div>
                        <div class="col-4 font-size-14">
                          จำนวนภาษี: 122
                        </div>
                        <div class="col-4 font-size-14">
                          ราคารวมภาษี: 4122
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a href="#" class="btn btn-danger">
          <i class="uil uil-times me-1"></i> Cancel
        </a>
        <a href="#" class="btn btn-success ms-1" @click="getData">
          <i class="uil uil-file-alt me-1"></i> Save
        </a>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
